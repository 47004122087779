<div class="container" style="display: flex; flex-direction: column; justify-content: center;">
    <div class="row margin-bottom-3--clear flex-items-xs-center">
      <div class="col-xs-12 col-md-8 col-lg-6">
        <sdx-card
          label="{{ 'register_step_2.label_card' | translate }}"
          label-aria-level="2"
          icon-name="icon-doctor"
          background="grey">
  
        <p>{{ "register_step_2.description" | translate }}</p>
          <p class="text-align-left"></p>
          
          <ol class="list single-line">
            <li>{{ "register_step_2.description_step_1" | translate }}</li>
            <li>{{ "register_step_2.description_step_2" | translate }}</li>
            <li>{{ "register_step_2.description_step_3" | translate }}</li>
          </ol>
          
          <sdx-button-group layout="fullwidth">
            <sdx-button (click)="continueWithLogin()" label="{{ 'register_step_2.button' | translate }}"></sdx-button>
          </sdx-button-group>
        </sdx-card>
      </div>
    </div>
  </div>
  