import { Component } from '@angular/core';
import { LoginService } from '../../services';

@Component({
  selector: 'app-register-step-2',
  templateUrl: './register-step-2.component.html',
  styleUrl: './register-step-2.component.scss'
})
export class RegisterStep2Component {

  constructor(private loginService: LoginService) {}

  continueWithLogin() {
    this.loginService.logout();
  }
}
