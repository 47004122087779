<div
  class="container"
  *ngrxLet="{ loadingOrg: loadingOrganization$, organizations: organizations$, allOrganizations: allOrganizations$} as ctx">
  <div class="row bg-gradient-1 padding-4 my-rounded-corner">
    <div class="col-xs-12">
      <h1 class="h1 white margin-2">{{ "organizations.header" | translate }}</h1>
    </div>
  </div>

  <div class="row paragraph flex-items-xs-center">
    <h5 class="navy margin-4">{{ "organizations.description" | translate }}</h5>
  </div>

  <input
    type="file"
    #orgImportFileInput
    style="display: none"
    (change)="importFromFile($event, ImportType.ORGANIZATION)" />
  <input
    type="file"
    #invitationImportFileInput
    style="display: none"
    (change)="importFromFile($event, ImportType.INVITATION)" />

  <div *ngIf="ctx.loadingOrg" class="row">
    <sdx-loading-spinner size="large" sr-hint="Loading. Please wait."></sdx-loading-spinner>
  </div>

  <div class="row margin-bottom-4" *ngIf="ctx.allOrganizations.length === 0">
    <div class="col-xs-12">
      <sdx-card
        layout="notification"
        icon-name="icon-information-circle"
        label="{{ 'organizations.empty_help' | translate }}"
        label-aria-level="3">
      </sdx-card>
    </div>
  </div>

  <div class="row" *ngIf="!ctx.loadingOrg && ctx.allOrganizations.length > 0">
    <div class="col-xs margin-top-2">
      <sdx-input
        id="search"
        type="search"
        placeholder="{{ 'organizations.search_placeholder' | translate }}"
        sr-hint="{{ 'organizations.search_placeholder' | translate }}"
        sr-hint-search-button="Search"
        [formControl]="searchControl" 
        ngDefaultControl/>
    </div>
    <div class="col-xs-12 col-md-auto margin-top-2" *ngIf="isSessionSwisscomHealthOrgAdminWrite$ | async">
      <sdx-button-group layout="responsive-center">
        <sdx-button
          label="{{ 'organizations.button_import_invitations' | translate }}"
          icon-name="icon-upload"
          (click)="openImportInvitationsFileDialog()"></sdx-button>
        <sdx-button
          label="{{ 'organizations.button_import_organizations' | translate }}"
          icon-name="icon-upload"
          (click)="openImportOrganizationsFileDialog()"></sdx-button>
        <sdx-button
          [disabled]="organizationBeingEdited"
          icon-name="icon-plus"
          label="{{ 'organizations.button_add_organization' | translate }}"
          (click)="initializeNewOrganization()"></sdx-button>
      </sdx-button-group>
    </div>
    <div class="col-xs-12 margin-top-2" *ngIf="organizationBeingEdited">
      <app-organization-form
        [organization]="organizationBeingEdited"
        (saveEmitter)="save($event)"
        (cancelEmitter)="cancel()">
      </app-organization-form>
    </div>
    <div class="col-xs-12 margin-top-2">
      <div id="organizations-table" class="table table--responsive table--highlight">
        <div class="table__wrapper">
          <table>
            <thead>
              <tr>
                <th data-type="text">{{ "organizations.table_label_name" | translate }}</th>
                <th data-type="text">{{ "organizations.table_label_type" | translate }}</th>
                <th data-type="text">{{ "organizations.table_label_glns" | translate }}</th>
                <th data-type="text" class="text-align-right">
                  {{ "organizations.table_label_actions" | translate }}
                </th>
              </tr>
            </thead>
            <tbody *ngIf="ctx.organizations.length === 0">
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td class="text-align-right">-</td>
            </tbody>
            <tbody *ngIf="ctx.organizations.length > 0">
              <tr *ngFor="let org of organizations$ | async">
                <td>{{ org.name }}</td>
                <td>{{ "data.organization_type." + org.type | lowercase | translate }}</td>
                <td>{{ org.glns.join(",") }}</td>
                <td class="text-align-right margin-0 padding-0">
                  <div class="toolbar" id="toolbarLocation">
                    <sdx-dialog
                      *ngIf="isSessionSwisscomHealthOrgAdminWrite$ | async"
                      label="{{ 'organizations.modal_label' | translate }}"
                      #deleteOrganizationModal
                      icon-name="icon-bin"
                      align-header-center>
                      <sdx-dialog-toggle>
                        <button class="toolbar__item item--show" aria-label="Delete">
                          <i class="icon icon-bin" aria-hidden="true"></i>
                        </button>
                      </sdx-dialog-toggle>

                      <sdx-dialog-content>
                        <p class="text-align-center margin-bottom-4">
                          {{ "organizations.modal_dialog_1" | translate }}<b>{{ org.name }}</b
                          >{{ "organizations.modal_dialog_2" | translate }}
                        </p>
                        <p>
                          <sdx-button-group layout="responsive-center">
                            <sdx-button
                              id="close-modal"
                              label="{{ 'organizations.modal_button_delete' | translate }}"
                              (click)="
                                deleteOrganization(org.id); deleteOrganizationModal.close()
                              "></sdx-button>
                            <sdx-button
                              id="first-action-element"
                              label="{{ 'organizations.modal_button_keep' | translate }}"
                              (click)="deleteOrganizationModal.close()"
                              theme="secondary"></sdx-button>
                          </sdx-button-group>
                        </p>
                      </sdx-dialog-content>
                    </sdx-dialog>

                    <button
                      class="toolbar__item item--show"
                      aria-label="Open"
                      (click)="selectOrganization(org.id)">
                      <i class="icon icon-arrow-right" aria-hidden="true"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
