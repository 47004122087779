import { Inject, Injectable, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService
} from "@azure/msal-angular";
import {
  AccountInfo,
  AuthenticationResult,
  EventMessage,
  EventType,
  PopupRequest,
  RedirectRequest,
  SsoSilentRequest
} from "@azure/msal-browser";
import { IdTokenClaims } from "@azure/msal-common";
import { Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { loginFailed, loginSuccessful } from "../../store";
import { buildB2CPolicyUrl } from "../../util/msal-helper";
import { NotificationService } from "../notification/notification.service";
import { LoginService } from "./login.service";

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  tfp: string;
  given_name: string;
  family_name: string;
  email: string;
  swisscomIamResources: string[];
  hasPassword: boolean;
  webauthn_isRegistered: boolean;
  totp_isRegistered: boolean;
};

@Injectable({
  providedIn: "root"
})
export class MsalLoginService implements OnDestroy, LoginService {
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalBroadcastService: MsalBroadcastService,
    private authService: MsalService,
    private router: Router,
    private store: Store,
    private notificationService: NotificationService
  ) {}

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }

  setup() {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            msg.eventType === EventType.SSO_SILENT_SUCCESS
        ),
        takeUntil(this._destroying$)
      )
      .subscribe({
        next: (result: EventMessage) => {
          let payload = result.payload as AuthenticationResult;
          let idToken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;

          if (idToken.tfp === environment.b2c.policyNames.signUpSignIn) {
            this.checkAndSetActiveAccount();
            this.dispatchLoginActionIfRequired();
          } else {
            this.showNotificationForSuccessfulPolicy(payload);
          }

          /**
           * For the purpose of setting an active account for UI update, we want to consider only the auth response resulting
           * from Signin flow. "acr" claim in the id token tells us the policy (NOTE: newer policies may use the "tfp" claim instead).
           * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
           */
          if (
            idToken.tfp === environment.b2c.policyNames.editProfile ||
            idToken.tfp === environment.b2c.policyNames.resetPassword
          ) {
            // retrieve the account from initial sing-in to the app
            const originalSignInAccount = this.authService.instance
              .getAllAccounts()
              .find(
                (account: AccountInfo) =>
                  account.idTokenClaims?.oid === idToken.oid &&
                  account.idTokenClaims?.sub === idToken.sub &&
                  (account.idTokenClaims as IdTokenClaimsWithPolicyId).tfp ===
                    environment.b2c.policyNames.signUpSignIn
              );

            let signUpSignInFlowRequest: SsoSilentRequest = {
              authority: buildB2CPolicyUrl(environment.b2c.policyNames.signUpSignIn),
              account: originalSignInAccount,
              scopes: ["openid", "offline_access", ...environment.api.scopes]
            };

            // silently login again with the signUpSignIn policy
            this.authService.ssoSilent(signUpSignInFlowRequest);
          }

          return result;
        },
        error: (error) => console.log(error)
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_FAILURE ||
            msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE
        ),
        takeUntil(this._destroying$)
      )
      .subscribe({
        next: (result: EventMessage) => {
          if (this.isPasswordResetError(result)) {
            let resetPasswordFlowRequest: RedirectRequest | PopupRequest = {
              authority: buildB2CPolicyUrl(environment.b2c.policyNames.resetPassword),
              scopes: ["openid", "offline_access"]
            };

            this.login(resetPasswordFlowRequest);
          } else if (this.hasUserCanceledOperation(result)) {
            const myAccountRoute = "my-account";
            this.reloadBeforeNavigate(myAccountRoute);
          } else if (this.hasUserCanceledOperation(result) && !this.hasActiveAccount()) {
            // Force login prompt
            const canceledThenLoginFlow: RedirectRequest | PopupRequest = {
              scopes: ["openid", "offline_access", ...environment.api.scopes],
              authority: buildB2CPolicyUrl(environment.b2c.policyNames.signUpSignIn),
              prompt: "login"
            };
            this.login(canceledThenLoginFlow);
          } else {
            // If unknown error, log and dispatch login failed action.
            console.log("Login failed.", result);
            return this.store.dispatch(loginFailed(result));
          }
        },
        error: (error) => {
          console.log("Login failed.", error);
          return this.store.dispatch(loginFailed(error));
        }
      });
  }

  private showNotificationForSuccessfulPolicy(payload: AuthenticationResult) {
    setTimeout(() => {
      let authority = payload?.authority.toLowerCase();
      if (authority.includes(environment.b2c.policyNames.editProfile.toLowerCase())) {
        this.notificationService.showTranslatedNotification("notifications.policy_successful_edit_profile", "confirmation");
      } else if (authority.includes(environment.b2c.policyNames.resetPassword.toLowerCase())) {
        this.notificationService.showTranslatedNotification("notifications.policy_successful_reset_password", "confirmation");
      } else if (authority.includes(environment.b2c.policyNames.manageTotp.toLowerCase())) {
        this.notificationService.showTranslatedNotification("notifications.policy_successful_manage_totp", "confirmation");
      } else if (authority.includes(environment.b2c.policyNames.manageWebAuthn.toLowerCase())) {
        this.notificationService.showTranslatedNotification("notifications.policy_successful_manage_webauthn", "confirmation");
      }
    }, 1000);
  }

  private hasUserCanceledOperation(result: EventMessage): boolean {
    return result.error != null && result.error.message.indexOf("AADB2C90091") > -1;
  }

  private isPasswordResetError(result: EventMessage): boolean {
    return result.error != null && result.error.message.indexOf("AADB2C90118") > -1;
  }

  private reloadBeforeNavigate(route: string): void {
    // Reload before navigating to avoid MSAL bug: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/5800
    window.location.reload();
    this.router
      .navigate([route])
      .then((result) => console.log(`Routing to ${route} result: [${result}]`));
  }

  logout(): Observable<void> {
    console.log("Logging out.");
    return this.authService.logoutRedirect();
  }

  editProfile(): void {
    const editProfileFlowRequest = {
      scopes: [],
      authority: buildB2CPolicyUrl(environment.b2c.policyNames.editProfile)
    };

    this.login(editProfileFlowRequest);
  }

  resetPassword(loginHint?: string, redirectStartPage?: string): void {
    const resetPasswordFlowRequest: RedirectRequest = {
      scopes: [],
      authority: buildB2CPolicyUrl(environment.b2c.policyNames.resetPassword),
      ...(loginHint ? { loginHint: loginHint } : {}),
      ...(redirectStartPage ? { redirectStartPage: redirectStartPage } : {})
    };

    this.login(resetPasswordFlowRequest);
  }

  manageTotp(loginHint?: string): void {
    const manageTotpFlowRequest = {
      scopes: [],
      authority: buildB2CPolicyUrl(environment.b2c.policyNames.manageTotp),
      ...(loginHint ? { loginHint: loginHint } : {})
    };

    this.login(manageTotpFlowRequest);
  }

  manageWebAuthn(state?: string): void {
    const manageWebAuthnFlowRequest = {
      scopes: [],
      authority: buildB2CPolicyUrl(environment.b2c.policyNames.manageWebAuthn),
      ...(state ? { state: state } : {})
    };

    this.login(manageWebAuthnFlowRequest);
  }

  private checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  private hasActiveAccount(): boolean {
    return !!this.authService.instance.getActiveAccount();
  }

  private dispatchLoginActionIfRequired() {
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount) {
      return;
    }

    let claims = activeAccount.idTokenClaims as IdTokenClaimsWithPolicyId;

    this.store.dispatch(
      loginSuccessful({
        sub: claims.sub!!,
        givenName: claims.given_name,
        familyName: claims.family_name,
        email: claims.email,
        swisscomIamResources: claims.swisscomIamResources,
        hasPassword: claims.hasPassword,
        webauthnRegistered: claims.webauthn_isRegistered,
        totpRegistered: claims.totp_isRegistered
      })
    );
  }

  private login(userFlowRequest?: RedirectRequest): void {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
        ...userFlowRequest
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect(userFlowRequest);
    }
  }
}
