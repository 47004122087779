import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoginService } from '../../services';

@Component({
  selector: 'app-register-step-1',
  templateUrl: './register-step-1.component.html',
  styleUrl: './register-step-1.component.scss'
})
export class RegisterStep1Component implements OnInit {
  mailAddress: string = "";
  
  constructor(
    private route: ActivatedRoute,
    private loginService: LoginService,
    ) {}

  ngOnInit(): void {
    this.mailAddress = this.route.snapshot.queryParamMap.get("mailAddress") ?? '';
  }

  continueWithPasswordReset() {
    if(!this.mailAddress) {
      return;
    }

    this.loginService.resetPassword(this.mailAddress, '/register-step-2');
  }
}
