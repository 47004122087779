import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { Invitation, RoleAssignment, RoleScope } from "../../models";
import {
  createInvitation,
  deleteInvitation,
  deleteRoleAssignment,
  selectAllInvitations,
  selectAllRoleAssignments, selectAllRoleDisplayNamesGroupedByRoleName,
  selectInvitationsLoading,
  selectLocationNameById,
  selectOrganizationNameById,
  selectOuNameById
} from "../../store";
import { map, Observable, of } from "rxjs";

@Component({
  selector: "app-user-management",
  templateUrl: "./user-management.component.html",
  styleUrls: ["./user-management.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserManagementComponent {
  roleAssignments$!: Observable<RoleAssignment[]>;
  invitations$!: Observable<Invitation[]>;
  roleDisplayNamesByName$: Observable<{ [name: string]: string }>;

  scopeFilter: RoleScope[] = [];
  statusFilter: ("accepted" | "invited")[] = [];

  invitationBeingEdited: Invitation | undefined;
  invitationsLoading$: Observable<boolean>;

  protected readonly RoleScope = RoleScope;

  constructor(
    private store: Store,
    private cdr: ChangeDetectorRef
  ) {
    this.updateRoleAssignments();
    this.updateInvitations();
    this.invitationsLoading$ = this.store.select(selectInvitationsLoading);
    this.roleDisplayNamesByName$ = this.store.select(selectAllRoleDisplayNamesGroupedByRoleName)
  }

  updateRoleAssignments() {
    this.roleAssignments$ = this.store
      .select(selectAllRoleAssignments)
      .pipe(map((roleAssignments) => this.filterRoleAssignments(roleAssignments)));
  }

  updateInvitations() {
    this.invitations$ = this.store
      .select(selectAllInvitations)
      .pipe(map((invitations) => this.filterInvitations(invitations)));
  }

  cancelInvite() {
    this.invitationBeingEdited = undefined;
  }

  newInvite() {
    this.invitationBeingEdited = {
      id: "",
      inviteeMailAddress: "",
      roleName: "",
      scope: RoleScope.ORG,
      entityId: "",
      entityName: ""
    };
  }

  saveInvitation(invitation: Invitation) {
    this.invitationBeingEdited = undefined;
    this.store.dispatch(createInvitation({ invitation }));
  }

  deleteRoleAssignment(roleAssignment: RoleAssignment) {
    this.store.dispatch(deleteRoleAssignment({ roleAssignment }));
  }

  deleteInvitation(invitation: Invitation) {
    this.store.dispatch(deleteInvitation({ invitation }));
  }

  getEntityNameByScopeAndName(scope: RoleScope, entityId: string): Observable<string> {
    if (scope === RoleScope.ORG) {
      return this.store.select(selectOrganizationNameById(entityId));
    } else if (scope === RoleScope.LOC) {
      return this.store.select(selectLocationNameById(entityId));
    } else if (scope === RoleScope.OU) {
      return this.store.select(selectOuNameById(entityId));
    } else return of("");
  }

  filterRoleAssignments(roleAssignments: RoleAssignment[]): RoleAssignment[] {
    return roleAssignments.filter((roleAssignment) => {
      const filterDoesNotExcludeScope =
        this.scopeFilter.includes(roleAssignment.scope) || this.scopeFilter.length === 0;
      const filterDoesNotExcludeStatus =
        this.statusFilter.includes("accepted") || this.statusFilter.length === 0;
      return filterDoesNotExcludeScope && filterDoesNotExcludeStatus;
    });
  }

  filterInvitations(invitations: Invitation[]): Invitation[] {
    return invitations.filter((invitation) => {
      const filterDoesNotExcludeScope =
        this.scopeFilter.includes(invitation.scope) || this.scopeFilter.length === 0;
      const filterDoesNotExcludeStatus =
        this.statusFilter.includes("invited") || this.statusFilter.length === 0;
      return filterDoesNotExcludeScope && filterDoesNotExcludeStatus;
    });
  }

  myScopeFilterChanged(event: Event) {
    this.scopeFilter = (event.target as HTMLSdxSelectElement).value;
    this.updateRoleAssignments();
    this.updateInvitations();
    this.cdr.markForCheck();
  }

  myStatusFilterChanged(event: Event) {
    this.statusFilter = (event.target as HTMLSdxSelectElement).value;
    this.updateRoleAssignments();
    this.updateInvitations();
    this.cdr.markForCheck();
  }

  getIconNameForScope(scope: RoleScope): string {
    switch (scope) {
      case RoleScope.ORG:
        return "icon-office";
      case RoleScope.LOC:
        return "icon-hospital-building";
      case RoleScope.OU:
        return "icon-topology";
    }
  }
}
